import React from "react"
import { Heading } from "@chakra-ui/core"

type Props = {}

export const Heading2: React.FC<Props> = ({ children }) => {
  return (
    <Heading
      as="h2"
      textAlign="center"
      fontSize={["1.1rem", "1.3rem", "1.5rem", "2.5rem"]}
      paddingX={["1rem", "3rem"]}
      fontWeight="bold"
      letterSpacing="0.3rem"
      textTransform="uppercase"
      color="black"
      paddingY="4rem"
    >
      {children}
    </Heading>
  )
}
